import React from "react"
import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import NumRastreo from '../components/pages/NumRastreo'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const IndexPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      companyName={pageContext.company}
      pageContext={pageContext}
      location={location}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <NumRastreo pageContext={pageContext} />
        </>
      )}
    />
  </GlobalFooterProvider>

)

export default IndexPage